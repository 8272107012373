const setupSVG = () => {
    if (document.querySelector('svg.clickable-svg')){
      const data = {
      "technik": {
        "title": "Digitale Ausstattung",
        "text": "<p>Smart-Boards, Laptops oder Beamer. An öffentlichen Schulen fehlt oft das Geld, um diese Ausstattung für alle Klassenzimmer und Schüler:innen bereitstellen zu können. Dann springt vielerorts der Förderverein ein. </p>"
      },
      "ausstattung": {
        "title": "Technik für die Naturwissenschaften",
        "text": "<p>Viele Messinstrumente für Versuche in Chemie und Physik sind sehr teuer.Manchmal finanzieren deswegen Fördervereine die Geräte.</p>"
      },
      "luftfilter": {
        "title": "Luftfilter",
        "text": "<p>Seit der Pandemie ist Präsenzunterricht in vielen Klassenzimmern nicht mehr möglich – zu eng und gefährlich. Luftfilter könnten Abhilfe schaffen. Doch bisher braucht der Staat für den Kauf viel Zeit. Deshalb legen mancherorts die Eltern zusammen und finanzieren die Geräte.</p>"
      },
      "buecher": {
        "title": "Bücher und Zeitungen",
        "text": "<p>Eine kleine Leihbücherei kann für Schüler:innen tolle Möglichkeiten bieten. Sie kostet zwar nicht viel Geld, aber es muss sich jemand darum kümmern: auch oft eine Aufgabe der Fördervereine.</p>"
      },
      "vortraege": {
        "title": "Vorträge und Berufsberatung",
        "text": "<p>In manchen Fördervereinen sind auch viele ehemalige Schüler:innen aktiv. Oft organisieren die Vereine dann Vorträge, bei denen die mittlerweile berufstätigen Alumni von ihrem Job und Karriereweg erzählen. So entsteht ein Netzwerk rund um die Schule. </p>"
      },
      "musik": {
        "title": "Musikinstrumente",
        "text": "<p>Eine der häufigeren Betätigungsfelder von Fördervereinen ist der Kauf und Verleih von Instrumenten. Denn die sind für ärmere Familien oft unbezahlbar. Schulorchester und Bands schaffen ein Gemeinschaftsgefühl zwischen den Schüler:innen und helfen dabei, Teamarbeit zu lernen.</p>"
      },
      "sport": {
        "title": "Sportgeräte und Freizeit",
        "text": "<p>Eine Tischtennisplatte kann den Unterschied machen zwischen einem langweiligen Pausenhof und einem Ort zum Austoben. Fördervereine finanzieren deshalb auch immer wieder Sportgeräte oder Klettergerüste.</p>"
      },
      "schulgarten": {
        "title": "Schulgarten",
        "text": "<p>Ein eigener Garten ist für die Schüler:innen nicht nur zum Entspannen gut. Auch für den Biologieunterricht bringt ein Schulgarten anschauliche Beispiele. Die perfekte Investition für einen Förderverein.</p>"
      },
      "austausch": {
        "title": "Austausch zwischen Förderverein und Schule",
        "text": "<p>In den allermeisten Fällen stehen die Fördervereine in engem Austausch mit der Schulleitung. Schulleitung und Fördervereinsvorstand in Personalunion –  auch das gibt es.</p>"
      },
      "schulidentitaet": {
        "title": "Schulidentität",
        "text": "<p>Fördervereine unterstützen die Schüler:innen und Lehrer:innen beispielsweise dabei, Projekte zur Schulgeschichte umzusetzen. Außerdem halten sie Denkmäler und Ausstellungen auf dem Schulgelände instand.</p>"
      },
      "schulessen": {
        "title": "Schulessen",
        "text": "<p>An manchen Schulen wird auch das Mitagessen von Fördervereinen organisiert oder bezuschusst. Immer wieder gibt es Familien, für die die Preise des Mensaessens zu hoch sind. In seltenen Fällen wird sogar die ganze Mensa vom Förderverein betrieben.</p>"
      },
      "klassenfahrten": {
        "title": "Klassenfahrten",
        "text": "<p>Fördervereine sorgen dafür, dass auch Kinder aus ärmeren Familien an Klassenfahrten teilnehmen können.</p>"
      }
    };

    const hoverBox = document.querySelector('.hover-box');
    const defaultHTML = hoverBox.innerHTML;

    document.querySelectorAll('.clickable').forEach(el => {
        el.addEventListener('click', (evt) => {
            evt.stopPropagation();


            if(el.classList.contains('is-active')){    
              hoverBox.classList.remove('is-active');            
              el.classList.remove('is-active');
              hoverBox.innerHTML = defaultHTML;
            }else{
                document.querySelectorAll('.clickable.is-active').forEach(activeEl => activeEl.classList.remove('is-active'));
                el.classList.add('is-active');

                const content = data[el.getAttribute('id')];
                hoverBox.innerHTML = `
                    <button type="button" class="modal-close is-large" aria-label="Schließen"></button>
                    <h2>${content.title}</h2>
                    ${content.text}
                `
                hoverBox.querySelector(".modal-close").addEventListener("click", resetSVG);
                hoverBox.classList.add('is-active');
            }
        });

        });

    const resetSVG = () => {
       document.querySelectorAll('.is-active').forEach(activeEl => {
         activeEl.classList.remove('is-active');
       })
       hoverBox.innerHTML = defaultHTML;
    }

    document.querySelector('svg.clickable-svg').addEventListener('click', (evt) => {
       resetSVG()
    });
  }
}

export default setupSVG;