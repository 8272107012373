import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faGlobe, faEuroSign, faBuilding} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faInstagram, faLinkedinIn, faXing, faFacebookF} from '@fortawesome/free-brands-svg-icons';

// add the imported icons to the library
library.add(faFacebook, faTwitter, faInstagram, faInstagram, faLinkedinIn, faEnvelope, faGlobe, faXing, faFacebookF, faEuroSign, faBuilding);

export default {
  init() {
    // JavaScript to be fired on all pages
    dom.watch();
    addMenuFunction();
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};


function addMenuFunction() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {

      // Add a click event on each of them
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {

          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');

        });
      });
    }
}
