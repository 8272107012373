import {
  Timeline
} from '@knight-lab/timelinejs';

const setupTimeline = () => {
    const el = document.querySelector('.timeline');
    if(el){
        const data = {
          'events': [{
              'start_date': {
                'year': '1995',
                'month': '1',
                'date': '1'
              },
              'end_date': {
                'year': '2006',
                'month': '12',
                'date': '31'
              },
              'group': 'Sokrates',
              'text': {
                'headline': 'Sokrates',
                'text': `
                            <div class='timeline-box'>
                              <h4 class="tl-headline-date">1995 - 2006</h4>
                                <h3 class="title is-3">Sokrates</h3>
                                <div class='tags'>
                                    <p class="budget-box"><strong>Budget: </strong><span class='tag budget'>2,61 Mio. Ecu</span></p>
                                    <p class="program-box"><strong>Inhalt: </strong>
                                    <span class='tag program'>Comenius (Schulpartnerschaften)</span>
                                    <span class='tag program'>Erasmus (Hochschulkooperation)</span>
                                    <span class='tag program'>Lingua (Spracherwerb)</span><span class='tag program'>Grundtvig (Erwachsenenbildung, seit 2000)</span>
                            <span class='tag program'>Minerva (Verbreitung von Informations- und
                                Kommunikationstechnologien, seit 2000)</span></p>
                                </div>
                                <div class='content'>
                                    <p><strong>Ziel: </strong>Mit Sokrates sollte die europäische Dimension sowie die Zusammenarbeit und Mobilität im Bereich der Allgemeinbildung gefördert werden.</p>
                                </div>
                            </div>
                            `,
              },
            },
            {
              'start_date': {
                "year": "1995",
                "month": "1",
                "date": "1"
              },
              "end_date": {
                "year": "2006",
                "month": "12",
                "date": "31"
              },
              'group': 'Leonardo da Vinci',
              'text': {
                'headline': 'Leonardo da Vinci',
                'text': `<div class='timeline-box'>
                        <h4 class="tl-headline-date">1995 - 2006</h4>
                                
                        <h3 class="title is-3">Leonardo da Vinci</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>620 Mio. Ecu und 1,15 Mrd. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>transnationale Pilotprojekte</span>
                            <span class='tag program'>Verbesserung der Sprachkompetenz</span>
                            <span class='tag program'>Aufbau transnationaler Netze</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Leonardo da Vinci sollte die Mobilität von Auszubildenden, jungen Arbeitnehmern und Berufsbildungsverantwortlichen fördern. </p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "1994",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2013",
                "month": "12",
                "day": "31"
              },
              'group': 'Alfa',
              'text': {
                'headline': 'Alfa',
                'text': `<div class='timeline-box'><h4 class="tl-headline-date">1994 - 2013</h4>
                                
                        <h3 class="title is-3">Alfa</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>31 Mio. Ecu und 129,6 Mio. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Schaffung von Netzwerken zwischen lateinamerikanischen und europäischen Universitäten</span>
                            <span class='tag program'>Kooperation mit privatem Sektor</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Alfa war ein Kooperationsprogramm zwischen der EU und Lateinamerika zur Unterstützung der Hochschulbildung für die soziale und ökonomische Entwicklung dieser Länder. </p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "2000",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2013",
                "month": "12",
                "day": "31"
              },
              'group': 'Jugend in Aktion',
              'text': {
                'headline': 'Jugend in Aktion',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">2000 - 2013</h4>
                                
                        <h3 class="title is-3">Jugend in Aktion</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>1,405 Mrd. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Jugend für Europa (innerhalb und außerhalb der EU)</span>
                            <span class='tag program'>Europäischer Freiwilligendienst (Innerhalb der EU und mit Nicht-EU-Staaten)</span>
                            <span class='tag program'>Jugend in der Welt</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Jugend in Aktion war ein Programm, das Jugendliche beim Berufserwerb und im gesellschaftlichen Engagement unterstützen sollte. Gefördert wurden Eigeninitiative, Unternehmergeist und Kreativität.</p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "2004"
              },
              "end_date": {
                "year": "2013"
              },
              'group': 'Erasmus Mundus',
              'text': {
                'headline': 'Erasmus Mundus',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">2004 - 2013</h4>
                                
                        <h3 class="title is-3">Erasmus Mundus</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>684 Mio. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Masterstudiengänge</span>
                            <span class='tag program'>Stipendien</span>
                            <span class='tag program'>Partnerschaften mit Hochschuleinrichtungen in Drittstaaten</span>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Mit Erasmus Mundus wurden Bildungsangebote im Hochschulbereich finanziert und hochqualifizierte Hochschulabsolvent:innen und Wissenschaftler:innen aus der ganzen Welt unterstützt. </p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "1990",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2013",
                "month": "12",
                "day": "31"
              },
              'group': 'Tempus',
              'text': {
                'headline': 'Tempus',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">1990 - 2013</h4>
                                
                        <h3 class="title is-3">Tempus</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>633,45 Mio. Ecu und 129,8 Mio. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Gemeinsame Mobilitäts- und Sprachprojekte</span>
                            <span class='tag program'>Reform von Hochschulstrukturen</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Tempus förderte eine verbesserte Zusammenarbeit zwischen Institutionen aus den EU-Mitgliedstaaten und EU-Partnerländern, um diese bei der Umstrukturierung ihres Hochschulsektors zu unterstützen.</p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "2006",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2013",
                "month": "12",
                "day": "31"
              },
              'group': 'edulink',
              'text': {
                'headline': 'Edulink',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">2006 - 2013</h4>
                                
                        <h3 class="title is-3">Edulink</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>o.A.</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Unterstützung von Unternehmertum durch einen Lehrfokus auf Management</span>
                            <span class='tag program'>Hilfe zur Selbsthilfe im Ausbau von E-Learning Netzwerken</span>
                            <span class='tag program'>Förderung von EU-AKP Netzwerken für Exzellenzinitiativen</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Edulink war ein Programm zum Kooperationsaufbau im Bereich der Hochschulbildung zwischen der EU und den AKP-Ländern (Afrika, Karibik, Pazifik).</p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "1990",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2007",
                "month": "12",
                "day": "31"
              },
              'group': 'Jean Monnet',
              'text': {
                'headline': 'Jean Monnet',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">1990 - 2007</h4>                                
                        <h3 class="title is-3">Jean Monnet</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>o.A.</span></p>
                            <p class="program-box"><strong>Programmbestandteile: </strong><span class='tag program'>Jean-Monnet Lehrstühle</span>
                            <span class='tag program'>Pflicht-Lehrveranstaltungen</span>
                            <span class='tag program'>Europa-Module</span>
                            <span class='tag program'>Europäische Jean-Monnet-Forschungszentren</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Jean Monnet legt im Bereich der Hochschulbildung den Fokus auf die Lehre der europäischen Integration hinsichtlich institutioneller, rechtlicher, politischer und wirtschaftlicher Aspekte. Unter Erasmus+ werden seit 2021 auch Schulen, Berufsbildungseinrichtungen und die Lehrer:innenausbildung gefördert. </p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "2007",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2013",
                "month": "12",
                "day": "31"
              },
              'group': 'Programm für lebenslanges Lernen',
              'text': {
                'headline': 'Programm für lebenslanges Lernen',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">2007 - 2013</h4>
                                
                        <h3 class="title is-3">Programm für lebenslanges Lernen</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>6,9 Mrd. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Comenius</span>
                            <span class='tag program'>Erasmus</span>
                            <span class='tag program'>Grundtvig</span>
                            <span class='tag program'>Leonardo Da Vinci</span>
                            <span class='tag program'>Jean-Monnet</span>
                            <span class='tag program'>weitere Querschnittsprogramme</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Das Programm für lebenslanges Lernen hatte das Ziel, die wissensbasierte Gesellschaft der EU zu fördern und verband dafür zahlreiche Vorgängerprogramme miteinander.</p>
                        </div>
                    </div>
                    `,
              },
            },
            {
              'start_date': {
                "year": "2014",
                "month": "1",
                "day": "1"
              },
              "end_date": {
                "year": "2027",
                "month": "12",
                "day": "31"
              },
              'group': 'Erasmus+',
              'text': {
                'headline': 'Erasmus+',
                'text': `<div class='timeline-box'>
                <h4 class="tl-headline-date">2014 - 2027</h4>
                                
                        <h3 class="title is-3">Erasmus+</h3>
                        <div class='tags'>
                            <p class="budget-box"><strong>Budget:</strong><span class='tag budget'>40,9 Mrd. Euro</span></p>
                            <p class="program-box"><strong>Inhalt: </strong><span class='tag program'>Lernmobilität</span>
                            <span class='tag program'>Förderung von Innovation und zum Austausch von bewährten Verfahren</span>
                            <span class='tag program'>Umsetzung politischer Reformen</span>
                            <span class='tag program'>Jugend</span>
                            <span class='tag program'>Sport</span>
                            <span class='tag program'>Jean Monnet</span></p>
                        </div>
                        <div class='content'>
                            <p><strong>Ziel: </strong>Erasmus+ verbindet die Bildungsprogramme der EU, die nicht mehr einzeln bestehen. Schwerpunkt ist die Förderung von lebenslangem Lernen für die bildungsbezogene, berufliche und persönliche Entwicklung in den Bereichen allgemeine und berufliche Bildung, Jugend und Sport.</p>
                        </div>
                    </div>
                    `,
              },
            },
            
          ]
        }

        new Timeline('timeline-embed',
          data, {
            language: '../wp-content/uploads/2021/widgets/bildung/timeline/de.json'
          });
    }
};

export default setupTimeline;