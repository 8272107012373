/**const setupSlides = () => {
    console.log('SETUP SLIDES');
    const slideContainers = document.querySelectorAll('.wp-block-group.slides');

    slideContainers.forEach(container => {
     
        const makeSlideVisible = (entries) => {
            entries.forEach(el => {
                if(el.isIntersecting){
                    const background = el.target.querySelector('img').getAttribute('src');

                    const backgroundPos = el.target.querySelector('img').parentElement.getAttribute('class').replace("wp-block-image size-large center-", "");

                    const position = backgroundPos.split("-"),
                    positionString = `${position[0]}%, ${position[1]}%`;

                    console.log(backgroundPos);
                    console.log(positionString);

                        console.log(background);
                        container.classList.add('has-background');
                        container.setAttribute('style', `background-image: url(${background});background-position: ${positionString};`);
                }
            })
        }

        let observer = new IntersectionObserver(makeSlideVisible);

        container.querySelectorAll('.sticky').forEach(el => {
            console.log(el);
            observer.observe(el)
        })
    });
}*/

const changeBackground = (el, container, loadedUrls) => {
    let background;

    if (window.innerHeight < window.innerWidth || !el.querySelector('figure.mobile img')) {
        const src = el.querySelector('img').getAttribute('src')
        background = loadedUrls[src] ? loadedUrls[src] : src;
    }else{
        const src = el.querySelector('figure.mobile img').getAttribute('src')
        background = loadedUrls[src] ? loadedUrls[src] : src;
    }

                    const backgroundPos = el.querySelector('img').parentElement.getAttribute('class').replace("credit-white ", "").replace("wp-block-image size-large center-", "");

                    const position = backgroundPos.split("-"),
                    positionString = `${position[0]}%, ${position[1]}%`;


                        const stickyBox = container.querySelector(".sticky-box");
                        stickyBox.classList.add('has-background');
                        stickyBox.setAttribute('style', `background-image: url(${background});background-position: ${positionString};`);

                        const caption = el.querySelector("figcaption")

                        stickyBox.querySelector(".slide-credit").innerHTML = caption.innerHTML;

                        if(el.querySelector("figure").classList.contains("credit-white")){
                            stickyBox.querySelector(".slide-credit").setAttribute("class", `slide-credit credit-white`);
                        }else{
                            stickyBox.querySelector(".slide-credit").setAttribute("class", `slide-credit`);
                        }
}

const setupSlides = () => {
    const slideContainers = document.querySelectorAll('.wp-block-group.slides');

    slideContainers.forEach(container => {
        const stickyElements = container.querySelectorAll('.sticky');

        let loadedUrls = {};
        stickyElements.forEach(el => {
            if (window.innerHeight < window.innerWidth || !el.querySelector('figure.mobile img')) {
                const img = el.querySelector("img");
                if(img.complete){
                    loadedUrls[img.getAttribute("src")] = img.currentSrc
                }else{
                    img.onload = function(){
                        loadedUrls[img.getAttribute("src")] = img.currentSrc
                    }
                }
            }else{
                const img = el.querySelector("figure.mobile img");
                if (img.complete) {
                  loadedUrls[img.getAttribute("src")] = img.currentSrc
                } else {
                  img.onload = function () {
                    loadedUrls[img.getAttribute("src")] = img.currentSrc
                  }
                }
            }
        });
        
        changeBackground(container.querySelector(".sticky"), container, loadedUrls);

        
        const makeSlideVisible = (entries) => {
            entries.forEach(el => {
                if(el.isIntersecting){
                    changeBackground(el.target, container, loadedUrls);
                }
            })
        }

        let observer = new IntersectionObserver(makeSlideVisible);

        container.querySelectorAll('.sticky').forEach(el => {
            
            observer.observe(el)
        })
    });
}

export default setupSlides