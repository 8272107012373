const setupInfobox = () => {
    const infoboxEl = document.createElement('div');
    infoboxEl.setAttribute('class', 'modal');

    infoboxEl.innerHTML = `
            <div class='modal-background'></div>
            <div class='modal-card'>
                    <div class='modal-card-body'>

                    </div>            
            </div>
            <button class='modal-close is-large' aria-label='Schließen'></button>
    `;

    const infoboxContentEl = infoboxEl.querySelector('.modal-card-body');
    document.querySelector('body').appendChild(infoboxEl);

    infoboxEl.addEventListener('click', () => {
        
        infoboxEl.classList.remove('is-active')
    });

    document.querySelector('.modal-close', () => {
        infoboxEl.classList.remove('is-active')
    })

    document.querySelectorAll('.info-button').forEach(el => {
          el.parentElement.nextElementSibling.classList.add('hidden');
        el.addEventListener('click', () => {
            infoboxContentEl.innerHTML = el.parentElement.nextElementSibling.innerHTML;
            infoboxEl.classList.add('is-active');

        });
    });
}

export default setupInfobox;