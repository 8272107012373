// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';

import ScrollReveal from 'scrollreveal';

import setupSlides from './util/slides';
import setupSVG from './util/svg-viewer.js';
import setupInfobox from './util/infobox.js';
import setupTimeline from './util/timeline.js';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => {
  routes.loadEvents();
  setupSlides();
  setupInfobox();
  setupSVG();
  setupTimeline();
  ScrollReveal({
    reset: true
  });

  document.querySelectorAll(".chat-message").forEach(el => {
    ScrollReveal().reveal(el, {
      opacity: 0,
      delay: parseInt(el.getAttribute("data-delay"))
    });
  })

  const easteregg = document.querySelector("#singstips19");
  if(easteregg){
    var audio = new Audio('https://bildung.journalistenschule-ifp.de/wp-content/uploads/2021/08/kapuzinerstrasse.mp3');
      
    easteregg.addEventListener("click", () => {
      if(audio.paused){
        audio.play();
      }else{
        audio.pause();
      }
    });
  }

  const easteregg3 = document.querySelector("#haha");
  if (easteregg3) {
    var audio2 = new Audio('https://bildung.journalistenschule-ifp.de/wp-content/uploads/2021/08/haha.mp3');

    easteregg3.addEventListener("click", () => {
      if (audio2.paused) {
        audio2.play();
      } else {
        audio2.pause();
      }
    });
  }

  const easteregg2 = document.querySelector(".teaser .has-inline-color.has-black-color");

  if(easteregg2){
    const easteregg2Box = document.createElement("p");
    easteregg2Box.classList.add("ifp-paragraph");
    easteregg2Box.innerHTML = `&#9835; ... sei über 40 Jahre im Himmel, bevor der Teufel merkt, Du bist schon tot.`
    
    document.querySelector("body").appendChild(easteregg2Box);

    easteregg2.addEventListener("click", (evt) => {
      
      easteregg2Box.setAttribute("style", `left: ${evt.pageX}px; top: ${evt.pageY}px;`);
      easteregg2Box.classList.toggle("ifp-active");
      
    });
  }
});

!function () {
  window.addEventListener("message", (function (e) {
    if (void 0 !== e.data["datawrapper-height"]) {
      var t = document.querySelectorAll("iframe");
      for (var a in e.data["datawrapper-height"])
        for (var r = 0; r < t.length; r++) {
          if (t[r].contentWindow === e.source) t[r].style.height = e.data["datawrapper-height"][a] + "px"
        }
    }
  }))
}();

!function (e, i, n, s) {
  var t = "InfogramEmbeds",
    d = e.getElementsByTagName("script")[0];
  if (window[t] && window[t].initialized) window[t].process && window[t].process();
  else if (!e.getElementById(n)) {
    var o = e.createElement("script");
    o.async = 1, o.id = n, o.src = "https://e.infogram.com/js/dist/embed-loader-min.js", d.parentNode.insertBefore(o, d)
  }
}(document, 0, "infogram-async");
